import React from 'react'
import { ProjectImg } from '../cmps/project-img';
export function Projects() {

    return (
        <div className="app-home main-view main-layout">
            <ProjectImg />
        </div>
    )

}